import { Card, Col } from "react-bootstrap";
import { Link } from "react-router-dom";

import styles from "./ObraEnCartelera.module.scss";
import moment from "moment";
import "moment/locale/es"; // Importa el locale español
import { useEffect, useState } from "react";

export const ObraEnCartelera = ({ data, id }) => {
  moment.locale("es"); // Establece el locale en español
  const [tieneSabado, setTieneSabado] = useState(false);
  const [tieneDomingo, setTieneDomingo] = useState(false);
  const [finDeSemana, setFinDeSemana] = useState("");
  const [tieneSemana, setTieneSemana] = useState(false); // Nuevo estado

  
  const [estreno, setEstreno] = useState(false);

  useEffect(() => {
    // Recorrer cada función para ver si es sábado o domingo
    data.funciones.forEach((funcion) => {
      const diaDeLaSemana = moment(
        funcion.fechaFuncion,
        "D [de] MMMM [de] YYYY"
      ).format("dddd");
      if (diaDeLaSemana === "sábado") {
        setTieneSabado(true);
      }
      if (diaDeLaSemana === "domingo") {
        setTieneDomingo(true);
      }
      if (["lunes", "martes", "miércoles", "jueves", "viernes"].includes(diaDeLaSemana)) {
        setTieneSemana(true);
      }
      if (data.estreno === "si") {
        setEstreno(true);
      } else {
        setEstreno(false);
      }
    });
  }, [data]);

  useEffect(() => {
    // Actualizar el estado según si hay funciones en días de semana, sábado, domingo o ambos
    if (tieneSemana && tieneSabado && tieneDomingo) {
      setFinDeSemana("También los sábados y domingos");
    } else if (tieneSemana && tieneSabado) {
      setFinDeSemana("También los sábados");
    } else if (tieneSemana && tieneDomingo) {
      setFinDeSemana("También los domingos");
    } else if (tieneSabado && tieneDomingo) {
      setFinDeSemana("Sábados y domingos");
    } else if (tieneSabado) {
      setFinDeSemana("Sábados");
    } else if (tieneDomingo) {
      setFinDeSemana("Domingos");
    } else {
      setFinDeSemana("");
    }
  }, [tieneSabado, tieneDomingo, tieneSemana]);

  return (
    <Col key={id}>
      <Link className={`${styles.obra}`} to={`/obras/${data.ID}`}>
        <Card className={`h-100 `}>
          <Col className={styles.imgContainer}>
            <Card.Img src={data.imgPortada} variant='top' />
            <h4>
              {finDeSemana !== "" ? (
                <Col className='mb-2'>
                  <small>{finDeSemana}</small>
                  <br />
                </Col>
              ) : (
                ""
              )}
              {data.title}
            </h4>
            {estreno && (
              <Col className={`${styles.esEstreno}`}>
                <b>{data.estrenoText}</b>
              </Col>
            )}
          </Col>
          <Card.Body className={`${styles.obrasPie}`}>
            <Card.Text>{data.subTitle}</Card.Text>
          </Card.Body>
        </Card>
      </Link>
    </Col>
  );
};
