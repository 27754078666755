import { Col, Container, Row } from "react-bootstrap";
import DOMPurify from "dompurify";
import { nosotrosData, paths, sponsors } from "../../data/dataSourese";
import { elenco } from "../../assets";
import { BannerTopPages } from "../BannerTopPages/BannerTopPages";
import styles from "./NosotrosPage.module.scss";
import { SponsorSlider } from "../SponsorSlider/SponsorSlider";
import { useContext, useEffect, useState } from "react";
import { DataContex } from "../../context/DataContext";
import { TimeLine } from "../TimeLine/TimeLine";
import { TituloSeccion } from "../TituloSeccion/TituloSeccion";
import { useLocation } from "react-router-dom";
import Helmet from "../Helmet";

export const NosotrosPage = () => {
  const { windowSize } = useContext(DataContex);

  const sanitizer = DOMPurify.sanitize;

  const location = useLocation();

  const [isXs, setIsXs] = useState(false);

  useEffect(() => {
    if (windowSize.windowsName === "appXs") {
      setIsXs(true);
    }
  }, [windowSize.windowsName]);

  useEffect(() => {
    // Verificar si hay un fragmento en la URL
    const hasFragment = location.hash.length > 0;

    // Si hay un fragmento, intentar desplazarse a él
    if (hasFragment) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        const yOffset = 110; // Ajusta este valor según sea necesario
        const y =
          element.getBoundingClientRect().top + window.pageYOffset - yOffset;

        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [location]);

  return (
    <>
      <Helmet title={"Nosotros"} link={`/${paths.nosotros}`} location={{}}
        description={`El Museo Viajero es una compañía de teatro para escuelas y colegios, fundada en 1995. Con propuestas educacionales, museológicas, históricas y literarias, creamos la comedia histórica infantil. Reconocidos por el Fondo Nacional de las Artes y otros, hemos colaborado con destacados profesionales y recibido múltiples premios.`}
     
        keywords={[
          "Museo Viajero",
          "teatro para escuelas",
          "teatro para colegios",
          "investigación histórica",
          "Héctor López Girondo",
          "Raquel Prestigiacomo",
          "Fabián Uccello",
          "comedia histórica infantil",
          "propuesta educacional",
          "propuesta museológica",
          "propuesta histórica",
          "propuesta literaria",
          "Fondo Nacional de las Artes",
          "Instituto Nacional del Teatro",
          "Proteatro",
          "Honorable Cámara de Diputados de la Nación",
          "Museo de la Historia del Traje",
          "Museo Histórico Cornelio Saavedra",
          "Museo Histórico Sarmiento",
          "Facultad de Veterinaria UBA",
          "Municipalidad de San Isidro",
          "Sociedad Científica Argentina",
          "Museo de Ciencias Naturales Florentino Ameghino",
          "Editorial EUDEBA",
          "La pequeña aldea",
          "Miguel Nigro",
          "Leticia Marín",
          "Cecilia Esteves",
          "Sergio Alem",
          "Jorge Rabito",
          "Miguel Magud",
          "Martín Dell´Aquila",
          "Juan Pablo Mastrangelo",
          "José Luis Calvo",
          "Apsis",
          "Gabriel Uccello",
          "Hugo Grosso",
          "Leonardo Gavriloff",
          "Carlos Canosa",
          "Belén Brito",
          "Lucía Rosso",
          "Paula Uccello",
          "Soledad Piacenza",
          "Daniel García",
          "Damián Albariño",
          "Malena Faletti",
          "Juan Ignacio Sandoval",
          "Norberto Benavidez",
          "Roberto Echaide",
          "Melina Saavedra",
          "Ayelén Lázaro",
          "Julián Felcman",
          "Lucas Herrera",
          "Daniel Mercado",
          "Ariel Langsman",
          "Álvaro Ruiz"
        ]}
      />
      <Container fluid>
        {isXs ? (
          <BannerTopPages img={elenco} positionY={"30%"} margBottom={"-30%"} />
        ) : (
          <BannerTopPages img={elenco} positionY={"30%"} margBottom={"-5%"} />
        )}
        <Container>
          <Row className={`justify-content-center`}>
            <Col lg={8} className={`${styles.main}`}>
              <h1>{nosotrosData.title}</h1>
              <div
                dangerouslySetInnerHTML={{
                  __html: sanitizer(nosotrosData.body),
                }}
              />
            </Col>
          </Row>
          <TituloSeccion titulo={"Cronología"} id={"crono"} />
          <TimeLine />
        </Container>
      </Container>
      <SponsorSlider slides={sponsors} />
    </>
  );
};
