import moment from "moment";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import {
  adentroTierraPortda,
  aldeaPortda,
  chocolatePortda,
  obligadaPortda,
  originariaPortda,
  sarmientoPortda,
  tesoroPortda,
  aldea001,
  aldea002,
  aldea003,
  aldea004,
  aldea005,
  aldea006,
  aldea007,
  aldea008,
  aldea009,
  aldea010,
  sarmiento001,
  sarmiento002,
  sarmiento003,
  sarmiento004,
  originaria001,
  originaria002,
  originaria003,
  tesoro001,
  tesoro002,
  tesoro003,
  tesoro004,
  tesoro005,
  tesoro006,
  tesoro007,
  tesoro008,
  tesoro009,
  tesoro010,
  tesoro011,
  adentroTierra001,
  adentroTierra002,
  adentroTierra003,
  sponsor01,
  sponsor02,
  sponsor03,
  sponsor04,
  sponsor05,
  sponsor06,
  sponsor07,
  sponsor08,
  sponsor09,
  obligada001,
  obligada002,
  obligada003,
  arbolito01,
  arbolito02,
  arbolito03,
  arbolitoPortda,
  cabildo001,
  cabildo002,
  cabildo003,
  cabildo004,
  cabildoPortda,
  semanaPortda,
  semana001,
  semana002,
  semana003,
  semana004,
  semana005,
  pulperia001,
  pulperia002,
  pulperia003,
  pulperia004,
  pulperia005,
  pulperia006,
  pulperia007,
  pulperia008,
  pulperia009,
  pulperiaPortda,
  mondongoPortada,
  mondongo001,
  mondongo002,
  mondongo003,
  mondongo004,
  mondongo005,
  mondongo006,
  belgrano001,
  belgrano002,
  belgrano003,
  belgrano004,
  belgrano005,
  belgrano006,
  belgrano007,
  belgranoPortada,
  celestiblancaPortada,
  corazonPortada,
  corazon001,
  corazon002,
  dosTiposPortada,
  cajonesPortada,
  cajones001,
  cajones002,
  cajones003,
  cajones004,
  viajecitosPortada,
  viajecitos002,
  viajecitos003,
  viajecitos004,
  viajecitos005,
  viajecitos006,
  viajecitos007,
  alegriaPortada,
  alegria001,
  alegria002,
  alegria003,
  alegria004,
  alegria005,
  alegria006,
  alegria007,
  caminitoPortada,
  caminito001,
  caminito002,
  casitaPortada,
  casita001,
  casita002,
  casita003,
  casita004,
  sanMartinPortada,
  sanMartin001,
  sanMartin002,
  sanMartin003,
  sanMartin004,
  sanMartin005,
  sanMartin006,
  sanMartin007,
  empanadasPortada,
  empanadas001,
  empanadas002,
  empanadas003,
  empanadas004,
  empanadas005,
  empanadas006,
  empanadas007,
  empanadas008,
  empanadas009,
  empanadas010,
  empanadas011,
  empanadas012,
  empanadas013,
  granaderosPortada,
  jugueteriaPortada,
  jugueteria001,
  jugueteria002,
  jugueteria003,
  libertadoresPortada,
  libertadores002,
  libertadores003,
  libertadores004,
  libertadores005,
  libertadores006,
  libertadores007,
  aLaMar002,
  aLaMar003,
  aLaMar004,
  aLaMar001,
  aLaMarPortada,
  regalo002,
  regalo003,
  regalo004,
  regaloPortada,
  feriaPortada,
  feria002,
  feria003,
  feria004,
  chocolate01,
  chocolate02,
  chocolate03,
  chocolate04,
  regaloBelgrano,
} from "../assets";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
  faYoutube,
} from "@fortawesome/free-brands-svg-icons";
import { faMobileScreenButton } from "@fortawesome/free-solid-svg-icons";

const calcularEdadEnAnios = (anio) => {
  // Obtén el año actual
  const anioActual = moment().year();
  // Calcula la diferencia de años
  const diferenciaAnios = anioActual - anio;
  return diferenciaAnios;
};

const createStartDate = ({ year = 1995, month = 1, day = 1, hour = 0 } = {}) => {
  return moment.utc({ year, month: month - 1, day, hour }).toISOString();
};

const contactData = {
  eMail: "info@elmuseoviajero.com.ar",
  phone: "+54 9 15 3255 6397",
  celPhone: "+54 9 15 3255 6399",
  whatsApp: process.env.REACT_APP_WHATEL,
};

const temasObras = {
  revolucion: "Para la revolución de mayo y el 25 de mayo",
  belgrano: "Para Manuel Belgrano y el 20 de junio",
  independencia: "Para la independencia y el 9 de julio",
  sanMartin: "Para San Martín y el 17 de agosto",
  sarmiento: "Para Sarmiento y el 11 de septiembre",
  dievrsidad: "Para la diversidad cultural y el 12 de octubre",
  tradicion: "Para el dia de la tradición y el 11 de noviembre",
  soberania: "Para el dia de la soberanía (combate de vuelta de obligado)",
  finDeAnio: "Para festejar fin de año",
  adultos: "Para adultos y secundarios",
};

const temasObrasArray = [
  { orden: 1, tema: "revolucion", temaDisplay: temasObras.revolucion },
  { orden: 2, tema: "belgrano", temaDisplay: temasObras.belgrano },
  { orden: 3, tema: "independencia", temaDisplay: temasObras.independencia },
  { orden: 4, tema: "sanMartin", temaDisplay: temasObras.sanMartin },
  { orden: 5, tema: "sarmiento", temaDisplay: temasObras.sarmiento },
  { orden: 6, tema: "dievrsidad", temaDisplay: temasObras.dievrsidad },
  { orden: 7, tema: "tradicion", temaDisplay: temasObras.tradicion },
  { orden: 8, tema: "soberania", temaDisplay: temasObras.soberania },
  { orden: 9, tema: "finDeAnio", temaDisplay: temasObras.finDeAnio },
  { orden: 10, tema: "adultos", temaDisplay: temasObras.adultos },
];

const ciclos = [
  "inicial",
  "primerCiclo",
  "segundoCiclo",
  "tercerCiclo",
  "secundario",
];

const publicaciones = {
  obrasIncluidas01: ["celestiblanca", "alegria", "libertadores", "tesoro",],
  obrasIncluidas02: ["arbolito", "mondongo", "viajecitos", "granaderos",],
  obrasIncluidasAldea: ["aldea",],
}

const sponsors = [
  { sponsorName: "Museos de Buenos Aires", svgFile: sponsor01 },
  { sponsorName: "Buenos Aires Ciudad", svgFile: sponsor02 },
  { sponsorName: "Pro Teatro", svgFile: sponsor03 },
  { sponsorName: "Museo Histórico Saavedra", svgFile: sponsor04 },
  { sponsorName: "H.C.D.R.A.", svgFile: sponsor05 },
  { sponsorName: "Ministerio de Cultura B. A.", svgFile: sponsor06 },
  { sponsorName: "Instituto Nacional del Teatro", svgFile: sponsor07 },
  { sponsorName: "Fondo Metropolitano", svgFile: sponsor08 },
  { sponsorName: "Fondo Nacional de las Artes", svgFile: sponsor09 },
];

const paths = {
  home: `/`,
  obras: `obras`,
  cartelera: `cartelera`,
  materiales: `materiales`,
  contacto: `contacto`,
  nosotros: `nosotros`,
  publicaciones: `publicaciones`,
  materialABM: `materialabm`,
  editMaterial: `editmaterial`,
  materialCreate: `materialcreate`,
  abm: `abm`,
  prensa: `prensa`,
  diarioDeMarcha: `diario-de-marcha`,
  calculador: `calculador`,
};

const socialMedia = [
  {
    socialNet: "Instagram",
    link: "https://www.instagram.com/elmuseoviajero/?hl=es-la",
    fontAwesome: faInstagram,
  },
  {
    socialNet: "YouTube",
    link: "https://www.youtube.com/user/museoviajero",
    fontAwesome: faYoutube,
  },
  {
    socialNet: "facebook",
    link: "https://www.facebook.com/museoviajero/",
    fontAwesome: faFacebook,
  },
];

const navBarNavigation = [
  {
    text: `Home`,
    link: `${paths.home}`,
  },
  {
    text: `Cartelera`,
    link: `${paths.cartelera}`,
  },
  {
    text: `Obras`,
    link: `${paths.obras}`,
  },
  {
    text: `Precios`,
    link: `${paths.calculador}`,
  },
  {
    text: `Sobre Nosotros`,
    link: `${paths.nosotros}`,
  },
  {
    text: `Material didáctico`,
    link: `${paths.materiales}`,
  },
  {
    text: `Prensa`,
    link: `${paths.prensa}`,
  },
  {
    text: `Libros publicados`,
    link: `${paths.publicaciones}`,
  },
  {
    text: `Contacto`,
    link: `${paths.contacto}`,
  },
];

const nosotrosData = {
  title: `Sobre nosotros`,
  body: `
        El Museo Viajero es una compañía de teatro para escuelas e investigación histórica fundada en 1995 por <b>Héctor López Girondo</b> (Titiritero del TGSM, actor y director de teatro), <b>Raquel Prestigiacomo</b> (Lic. y prof. en Letras, docente de la UBA, semióloga y escritora) y <b>Fabián Uccello</b> (historiador, actor y director de teatro).<br/>
        <br/>
        <h3>El Museo Viajero parte de cuatro propuestas:</h3> 
        <br/>
          <ul>
            <li>
              Propuesta educacional: ver el teatro como una herramienta pedagógica.
            </li>
            <li>
              Propuesta museológica: integrar el objeto al observador.
            </li>
            <li>
              Propuesta histórica: recrear el discurso histórico tradicional.
            </li>
            <li>
              Propuesta literaria: incorporar la narración descriptiva y la dramatización.
            </li>
          </ul>
             De la combinación de estas cuatro propuestas surge un género propio: <strong>la comedia histórica infantil</strong>.</br/>
        <br/>
        La seriedad en la selección de los contenidos de nuestras obras de teatro para colegios y la eficacia en su presentación está avalada por años de ejercicio docente, investigación histórico-literaria y una larga y fructífera actividad teatral infantil.<br/>
        <br/>
        Durante estos 30 años haciendo obras de teatro para escuelas, hemos recibido el reconocimiento entre otros muchos del <b>Fondo Nacional de las Artes</b>, del <b>Instituto Nacional del Teatro</b>, <b>Proteatro</b>, <b>Honorable Cámara de Diputados de la Nación</b>, el Museo de la Historia del Traje, el Museo Histórico Cornelio Saavedra, el Museo Histórico Sarmiento, la Facultad de Veterinaria (UBA), la Municipalidad de San Isidro, la Sociedad Científica Argentina, el Museo de Ciencias Naturales Florentino Ameghino.  Y de la Editorial <b>EUDEBA</b> que ha publicado cuatro ediciones de La pequeña aldea y los tomos 1 y 2 de la compilación de nuestras comedias, bajo el título Comedias del Museo Viajero, editados en 2023.</br/>
        <br/>
        En nuestra larga trayectoria iniciada en 1995 hemos contado con la colaboración de prestigiosos profesionales como el escenógrafo y artista plástico Miguel Nigro, las coreógrafas Leticia Marín y Cecilia Esteves, los músicos Sergio Alem, Jorge Rabito, Miguel Magud y Martín Dell´Aquila, el vestuarista Juan Pablo Mastrangelo, el iluminador José Luis Calvo, la gráfica comunicacional de Apsis y la prensa de Gabriel Uccello.</br/>
        <br/>
        Por nuestras filas han pasado destacados actores y directores como Hugo Grosso (Premio Molière), Leonardo Gavriloff (Premio Estrella de Mar), Carlos Canosa, Belén Brito, Lucía Rosso, Paula Uccello, Soledad Piacenza, Daniel García, Damián Albariño, Malena Faletti, Juan Ignacio Sandoval, Norberto Benavidez, Roberto Echaide, Melina Saavedra, Ayelén Lázaro, Julián Felcman, Lucas Herrera, Daniel Mercado, Ariel Langsman y Álvaro Ruiz</br/>
        `,
};

const cronologia = [
  {
    year: 1995,
    yearEvent: [
      `   Se funda el grupo con el estreno de Un siglo en un ratito.`,
    ],
  },
  {
    year: 1996,
    yearEvent: [
      `   <a href='./obras/aldea' target='_blank'>La pequeña aldea, con maqueta itinerante realizada por el escenógrafo Miguel Nigro.</a>`,
    ],
  },
  {
    year: 1997,
    yearEvent: [
      `   Construimos una sala de teatro en el Museo Histórico de Buenos Aires Cornelio Saavedra para una segunda maqueta.`,
    ],
  },
  {
    year: 1998,
    yearEvent: [
      `   Somos declarados de Interés Cultural por el Gobierno de la Ciudad de Buenos
                    Aires y convocados por el Museo Nacional de la Historia del Traje para la
                    TrajeComedia del traje.`,
    ],
  },
  {
    year: 1999,
    yearEvent: [
      `   Estrenamos Cristóbal Colón, un viaje redondo.`,
      `   <a href='https://www.eudeba.com.ar/Papel/9789502323213/La+peque%c3%b1a+aldea' target='_blank'>La editorial Eudeba publica La pequeña aldea, vida cotidiana 1800-1860 de R.
                    Prestigiacomo y F. Uccello.</a>`,
      `   Implementamos nuestra página web.`,
    ],
  },
  {
    year: 2000,
    yearEvent: [
      `   <a href='./obras/sanMartin' target='_blank'>Estrenamos San Martín, un general sin Remedios.</a>`,
      `   Se funda una filial en San Pablo, Brasil que recupera la historia local.`,
    ],
  },
  {
    year: 2001,
    yearEvent: [
      `   <a href='https://www.eudeba.com.ar/Papel/9789502323213/La+peque%c3%b1a+aldea' target='_blank'>Segunda edición del libro La pequeña aldea, vida cotidiana en 1800-1860.</a>`,
    ],
  },
  {
    year: 2002,
    yearEvent: [
      `   <a href='./obras/aldea' target='_blank'>La pequeña aldea recibe un premio de Proteatro y es declarada de Interés
                    Cultural por la Honorable Cámara de Diputados de la Nación.</a>`,
    ],
  },
  {
    year: 2003,
    yearEvent: [
      `   San Martín, un general sin Remedios recibe un premio de Proteatro.`,
    ],
  },
  {
    year: 2004,
    yearEvent: [
      `   Estrenamos <a href='./obras/belgrano' target='_blank'>Manuel Belgrano, ensayo ¡General!</a> y La historia de los inventos con
                    premios de Proteatro, Fondo Nacional de las Artes e Instituto Nacional del Teatro.`,
      `   Se estrena en San Pablo, Brasil A pequenha aldeia.`,
    ],
  },
  {
    year: 2005,
    yearEvent: [
      `   <a href='./obras/cabildo' target='_blank'>Estrenamos Cabildo Abierto, la película</a> y Sarmiento, un Domingo en la escuela
                    con Proteatro, Fondo Nacional de las Artes e Instituto Nacional del Teatro.`,
    ],
  },
  {
    year: 2006,
    yearEvent: [
      `   Somos convocados por la Municipalidad de San Isidro para realizar un
                    espectáculo itinerante y una maqueta: Trescientos años de historia para armar`,
    ],
  },
  {
    year: 2007,
    yearEvent: [
      `   Estrenamos Gutiérrez y la máquina del tiempo con premio del Instituto Nacional
                    del Teatro.`,
      `   El Fondo de Cultura Metropolitano subsidia la construcción de un "trailer-escenario-
                    móvil".`,
    ],
  },
  {
    year: 2008,
    yearEvent: [
      `   El trailer-escenario es reconocido como “sala teatral” por Proteatro.`,
      `   <a href='./obras/9cajones' target='_blank'>Estrenamos Nueve cajones mágicos en julio</a> (la historia de la Independencia) con
                    premio de Proteatro.`,
      `   Recibimos premio de Proteatro para nuestra primera comedia Un siglo en un ratito.`,
    ],
  },
  {
    year: 2009,
    yearEvent: [
      `   <a href='./obras/mondongo' target='_blank'>Estrenamos Mondongo para Manuel</a> con premio de Proteatro.`,
      `   Estrenamos el drama policial La trampa infinita de R. Prestigiacomo con premio
                    Proteatro.`,
      `   Estrenamos, en coproducción con Los Similares, la comedia de magia El
                    reemplazo - Primera misión.`,
      `   Reestrenamos La guerra muda de Leonardo Gavriloff con premio del Fondo
                    Nacional de las Artes y Proteatro.`,
      `   <a href='./obras/regalo' target='_blank'>Estrenamos Un regalo muuuy especial (la historia de Papá Noel)</a>, con premio de
                    Proteatro.`,
    ],
  },
  {
    year: 2010,
    yearEvent: [
      `   Fuimos convocados por la Casa del Bicentenario para inaugurar los festejos con
                    nuestra obras.`,
      `   Estrenamos Fiesta en las calles y participamos en la Noche de los Museos.`,
    ],
  },
  {
    year: 2011,
    yearEvent: [
      `   Estrenamos <a href='./obras/originaria' target='_blank'>OriginariaMente Argentina</a> y 2x4 en El Cairo con adaptación de R.
                    Prestigiacomo, con premio de Proteatro`,
    ],
  },
  {
    year: 2012,
    yearEvent: [
      `   Estrenamos <a href='./obras/celestiblanca' target='_blank'>Celestiblanca</a> con premio de Proteatro y La historia de la magia con
                    dirección de Fabián Uccello.`,
    ],
  },
  {
    year: 2013,
    yearEvent: [
      `   Estrenamos <a href='./obras/semana' target='_blank'>La Gran Semana de Mayo</a> con premio de Proteatro.
                    Reestrenamos Historias con desperdicios de Carlos Canosa y Héctor López Girondo`,
    ],
  },
  {
    year: 2014,
    yearEvent: [
      `   <a href='https://www.eudeba.com.ar/Papel/9789502323213/La+peque%c3%b1a+aldea' target='_blank'>Se publica la 4º edición de La pequeña aldea, de R. Prestigiacomo y F. Uccello.</a>
                    Estrenamos <a href='./obras/arbolito' target='_blank'>El arbolito frente al cabildo de R. Prestigiacomo y F. Uccello.`,
    ],
  },
  {
    year: 2015,
    yearEvent: [
      `   Estrenamos <a href='./obras/jugueteria' target='_blank'>La juguetería de José</a> y <a href='./obras/aLaMar' target='_blank'>Juntos a la mar</a> con premio Proteatro.`,
      `   Estrenamos <a href='./obras/feria' target='_blank'>Tres en una feria</a>, sobre cuentos de Fontanarrosa.`,
    ],
  },
  {
    year: 2016,
    yearEvent: [
      `   Estrenamos <a href='./obras/alegria' target='_blank'>Alegría de la Libertad</a>, <a href='./obras/casita' target='_blank'>Habla la casita</a>, <a href='./obras/viajecitos' target='_blank'>Viajecitos de Independencia.</a>`,
    ],
  },
  {
    year: 2017,
    yearEvent: [
      `   Estrenamos  <a href='./obras/granaderos' target='_blank'>Cuentos con granaderos</a>, 
                                <a href='./obras/libertadores' target='_blank'>Libertadores de América</a> y 
                                <a href='./obras/empanadas' target='_blank'>Empanadas para José</a>`,
      `   Participamos en la Noche de los Museo con Una loca, loca noche en el museo, intervención teatral en el Museo Saavedra`,
    ],
  },
  {
    year: 2018,
    yearEvent: [
      `   Estrenamos  <a href='./obras/pulperia' target='_blank'>La pulpería de Jacinto
                                </a> y <a href='./obras/tesoro' target='_blank'>El tesoro argentino.</a>`,
    ],
  },
  {
    year: 2019,
    yearEvent: [
      `   Estrenamos  <a href='./obras/corazon' target='_blank'>Pequeño corazón</a>,
                                <a href='./obras/caminito' target='_blank'>Caminito Criollo</a> y
                                <a href='./obras/adentroTierra' target='_blank'>Viaje adentro de la Tierra.</a>`,
    ],
  },
  {
    year: "2020 - 21 y 22",
    yearEvent: [
      `   Estrenamos el vídeo de animación Diario de marcha a Rosario, de
                    Manuel Belgrano. <a href='https://www.clarin.com/espectaculos/teatro/obras-museo-viajero-on-line-pequenos_0_pYavDVSse.html' target='_blank'>Implementamos una plataforma on demand</a>, con vídeos de nuestra
                    obras, recursos y actividades para escuelas.`,
    ],
  },
  {
    year: 2023,
    yearEvent: [
      `   Estrenamos  <a href='./obras/chocolate' target='_blank'>Chocolate por la libertad,</a>
                                <a href='./obras/obligada' target='_blank'>Obligada estaba la Vuelta,</a>`,
      `   ELa editorial Eudeba publica    <a href='https://www.eudeba.com.ar/Papel/9789502333427/Comedias+del+Museo+Viajero+1' target='_blank'>Comedias del Museo Viajero 1</a> y
                                                    <a href='https://www.eudeba.com.ar/Papel/9789502333434/Comedias+del+Museo+Viajero+2' target='_blank'>Comedias del Museo Viajero 2.</a>`,
    ],
  },
];

const dataSource = [
  // Para la revolución de mayo y el 25 de mayo
  // aldea
  {
    ID: "aldea",
    temaObra: temasObras.revolucion,
    title: "La pequeña aldea",
    imgPortada: aldeaPortda,
    subTitle: "Vida Cotidiana en 1810",
    startDate: createStartDate(),
    body: ` 
        Única muestra de la ciudad colonial, sus costumbres y personajes: el Cabildo, la Recova Vieja, el Fuerte,
        la Alameda, el matadero, la Plaza de Toros, las iglesias, el Café de Marcos y los revolucionarios, los
        vendedores, la gente y su vestimenta, los medios de transporte, comidas y bebidas, música y bailes.<br/>
        Una sorprendente maqueta de 25 m2 que abarca desde el Riachuelo hasta la Recoleta. Actores e
        historiadores en escena completan esta inolvidable experiencia teatral.
        `,
    destacado: "",
    tipoDeObra: "Comedia histórica",
    pie: "",
    images: [
      {
        imgName: "aldeaImg01",
        url: aldea001,
      },
      {
        imgName: "aldeaImg02",
        url: aldea002,
      },
      {
        imgName: "aldeaImg03",
        url: aldea003,
      },
      {
        imgName: "aldeaImg04",
        url: aldea004,
      },
      {
        imgName: "aldeaImg05",
        url: aldea005,
      },
      {
        imgName: "aldeaImg06",
        url: aldea006,
      },
      {
        imgName: "aldeaImg07",
        url: aldea007,
      },
      {
        imgName: "aldeaImg08",
        url: aldea008,
      },
      {
        imgName: "aldeaImg09",
        url: aldea009,
      },
      {
        imgName: "aldeaImg10",
        url: aldea010,
      },
    ],
    temas: [],
    premios: [
      {
        premio:
          "Declarada de Interés Cultural por el Gob. de la Ciudad de Buenos Aires.",
      },
      { premio: "Premio Proteatro 2002, Gob. de la Ciudad de Buenos Aires" },
    ],
    keywords: [
      "La pequeña aldea",
      "Vida Cotidiana en 1810",
      "Comedia histórica",
      "Cabildo",
      "Recova Vieja",
      "Fuerte",
      "Alameda",
      "Matadero",
      "Plaza de Toros",
      "Café de Marcos",
      "Revolucionarios",
      "Vendedores ambulantes",
      "Colonial",
      "Transporte",
      "Comidas",
      "Bebidas",
      "Música",
      "Bailes",
      "Actores",
      "Historiadores",
      "Experiencia teatral",
      "Declarada de Interés Cultural",
      "Premio Proteatro 2002",
      "Gobierno de la Ciudad de Buenos Aires",
    ],
  },

  // arbolito
  {
    ID: "arbolito",
    temaObra: temasObras.revolucion,
    title: "El arbolito frente al Cabildo",
    imgPortada: arbolitoPortda,
    subTitle: "La historia de la libertad",
    startDate: createStartDate(),
    body: `
            El señor Director quiere contar cómo fue creciendo la idea de la Libertad que terminó en la Revolución de Mayo de 1810 pero como sus ayudantes no entienden se le ocurre compararla con un árbol que va creciendo desde la semilla.<br/>
            Para ello traslada la escena al patio del Café de la Victoria, que entonces estaba frente al Cabildo. Allí viven Rosita y Manuel que quieren plantar un arbolito de laurel pero para hacerlo deberán pedir permiso al virrey ya que nada podía hacerse en esa época sin permiso.<br/>
            El virrey no autoriza y deben enviarle una carta al rey, lo cual permitirá al director y Gutiérrez mostrar el larguísimo viaje del correo de la época: desde Buenos Aires hasta México y de allí a España. Finalmente el árbol es plantado sin permiso y será el emblema de la lucha por la libertad.<br/>
            Con canciones inspiradas en compositores argentinos y latinoamericanos se irán presentando vestimentas y costumbres virreinales, el control del rey sobre todo, el correo, un cartero famoso, los viajes y los cambios que trajo la Revolución.
            `,
    destacado: "",
    tipoDeObra: "Comedia musical para títeres y actores",
    pie: `Esta obra es para Inicial y Primer ciclo. Cuando viaja a los colegios va acompañada de “Cabildo Abierto” para los más grandes`,
    images: [
      {
        imgName: "arbolito01",
        url: arbolito01,
      },
      {
        imgName: "arbolito02",
        url: arbolito02,
      },
      {
        imgName: "arbolito03",
        url: arbolito03,
      },
    ],
    temas: [],
    premios: [],
    keywords: [
      "El arbolito frente al Cabildo",
      "La historia de la libertad",
      "Revolución de Mayo",
      "Café de la Victoria",
      "Árbol de laurel",
      "Virrey",
      "Permiso real",
      "Correo colonial",
      "Emblema de la lucha por la libertad",
      "Canciones argentinas",
      "Canciones latinoamericanas",
      "Vestimentas virreinales",
      "Costumbres virreinales",
      "Control del rey",
      "Cartero famoso",
      "Viajes coloniales",
      "Cambios durante la Revolución",
      "Comedia musical",
      "Títeres y actores",
      "Educación inicial",
      "Primer ciclo",
      "Cabildo Abierto",
    ],
  }
  ,
  //cabildo
  {
    ID: "cabildo",
    temaObra: temasObras.revolucion,
    title: "Cabildo Abierto",
    imgPortada: cabildoPortda,
    subTitle: "Los antecedentes de la Revolución de Mayo",
    startDate: createStartDate(),
    body: `
            La señorita Gutiérrez y su novio se introducen en el mundo del cine y deciden “filmar” la Semana de Mayo.<br/>
            Con la ayuda del señor Director y de variadas técnicas (que incluyen zarzuela, ritmos latinoamericanos, títeres de figuras planas y hasta un inmenso cabildo) nos presentan los hechos históricos que precedieron a la revolución (el monopolio, las invasiones inglesas, la invasión napoleónica) y las difíciles discusiones de la Semana de Mayo.<br/>
            Simulando un set de filmación aparecerán en escena vendedores ambulantes, soldados, hombres y mujeres patriotas, la verdad sobre las cintitas, Cisneros, Saavedra, Belgrano, Moreno y el pueblo mismo llevarán adelante la Revolución delante de nuestros ojos.
            `,
    destacado: "",
    tipoDeObra: "Comedia musical",
    pie: "Esta obra es para Segundo y Tercer ciclo. Cuando viaja a los colegios va acompañada de “El arbolito frente al cabildo” para los más chicos",
    images: [
      {
        imgName: "cabildo001",
        url: cabildo001,
      },
      {
        imgName: "cabildo002",
        url: cabildo002,
      },
      {
        imgName: "cabildo003",
        url: cabildo003,
      },
      {
        imgName: "cabildo004",
        url: cabildo004,
      },
    ],
    temas: [],
    premios: [
      { premio: "Premio Proteatro 2005" },
      { premio: "Premio Fondo Nacional de las Artes 2005" },
      { premio: "Premio Instituto Nacional del Teatro 2005" },
    ],
    keywords: [
      "Cabildo Abierto",
      "Antecedentes de la Revolución de Mayo",
      "Semana de Mayo",
      "Historia argentina",
      "Monopolio",
      "Invasiones inglesas",
      "Invasión napoleónica",
      "Zarzuela",
      "Ritmos latinoamericanos",
      "Títeres",
      "Vendedores ambulantes",
      "Soldados",
      "Mujeres patriotas",
      "Cintitas",
      "Cisneros",
      "Saavedra",
      "Belgrano",
      "Moreno",
      "Comedia musical",
      "Segundo ciclo",
      "Tercer ciclo",
    ],
  }
  ,
  // semana
  {
    ID: "semana",
    temaObra: temasObras.revolucion,
    title: "La Semana de Mayo",
    imgPortada: semanaPortda,
    subTitle: "La otra cara de la Revolución",
    startDate: createStartDate(),
    body: `
            Basada en las crónicas imaginarias que en 1885 escribiera el historiador Vicente Fidel López, hijo del autor del Himno Nacional.<br/>
            Con la idea de dar su conferencia sobre la otra cara de la Revolución, es decir: sobre la gente del pueblo, los desconocidos participantes de aquellas jornadas de Mayo, el señor director consigue que el Museo Saavedra le preste un antiguo baúl y así decorar e ilustrar el hallazgo de unas imaginarias cartas escritas en 1810. Esto genera en Gutiérrez, su inquieto ayudante, una incontenible curiosidad por ver qué hay dentro del baúl.<br/>
            En los sucesivos intentos fallidos por abrirlo, y la insistencia del director por atenerse solo a dramatizar las encantadoras cartas de V. Fidel López, se irán presentando la participación de las mujeres, los negros, los orilleros, los españoles. Un final inesperado sorprende a Gutiérrez mostrándole la otra cara de la curiosidad.
            `,
    destacado: "",
    tipoDeObra: "Comedia histórica",
    pie: "Esta obra es para Segundo y Tercer ciclo. Cuando viaja a los colegios va acompañada de “La pulpería de Jacinto” para los más chicos",
    images: [
      {
        imgName: "semana001",
        url: semana001,
      },
      {
        imgName: "semana002",
        url: semana002,
      },
      {
        imgName: "semana003",
        url: semana003,
      },
      {
        imgName: "semana004",
        url: semana004,
      },
      {
        imgName: "semana005",
        url: semana005,
      },
    ],
    temas: [
      {
        tema: `Basada en las crónicas imaginarias que en 1885 escribiera el historiador Vicente Fidel López, hijo del autor del Himno Nacional.`,
      },

    ],
    premios: [{ premio: "Proteatro 2013" }],
    keywords: [
      "La Semana de Mayo",
      "La otra cara de la Revolución",
      "Vicente Fidel López",
      "Himno Nacional",
      "Curiosidad",
      "Participación popular",
      "Cartas de 1810",
      "Mujeres",
      "Negros",
      "Orilleros",
      "Españoles",
      "Comedia histórica",
      "Segundo ciclo",
      "Tercer ciclo",
    ],
  }
  ,
  // pulperia
  {
    ID: "pulperia",
    temaObra: temasObras.revolucion,
    title: "La pulpería de Jacinto",
    imgPortada: pulperiaPortda,
    subTitle: "Un descanso camino al cabildo",
    startDate: createStartDate(),
    body: `
            La señorita Gutiérrez y su novio quieren sorprender al director con una obra sobre las antiguas costumbres campestres pero solo tienen una canción de amor que trata de una chica que va a vender quesos a la ciudad.<br/>
            Inspirado por lo romántico de la canción, el director sugiere una pulpería, al borde de la ciudad, dirigida por Jacinto, y visitada por todo el mundo: un cacique pampa que vende plumas de ñandú, dos gauchos cantores, un médico vacunador, la vendedora de quesos y hasta Mariquita Sánchez de Thompson y Cornelio Saavedra de camino al cabildo.
            `,
    destacado: "",
    tipoDeObra: "Comedia musical",
    pie: "Esta obra es para Inicial y Primer ciclo. Cuando viaja a los colegios va acompañada de “La Semana de Mayo” para los más grandes",
    images: [
      {
        imgName: "pulperia001",
        url: pulperia001,
      },
      {
        imgName: "pulperia002",
        url: pulperia002,
      },
      {
        imgName: "pulperia003",
        url: pulperia003,
      },
      {
        imgName: "pulperia004",
        url: pulperia004,
      },
      {
        imgName: "pulperia005",
        url: pulperia005,
      },
      {
        imgName: "pulperia006",
        url: pulperia006,
      },
      {
        imgName: "pulperia007",
        url: pulperia007,
      },
      {
        imgName: "pulperia008",
        url: pulperia008,
      },
      {
        imgName: "pulperia009",
        url: pulperia009,
      },
    ],
    temas: [],
    premios: [],
    keywords: [
      "La pulpería de Jacinto",
      "Un descanso camino al cabildo",
      "Antiguas costumbres",
      "Canción de amor",
      "Pulpería",
      "Cacique pampa",
      "Plumas de ñandú",
      "Gauchos",
      "Médico vacunador",
      "Mariquita Sánchez de Thompson",
      "Cornelio Saavedra",
      "Comedia musical",
      "Inicial",
      "Primer ciclo",
    ],
  }
  ,
  // chocolate
  {
    ID: "chocolate",
    temaObra: temasObras.revolucion,
    title: "Chocolate por la Libertad",
    imgPortada: chocolatePortda,
    subTitle: "Las noticias, la prensa, las reuniones secretas durante la Revolución",
    startDate: createStartDate(),
    body: `
          El olvidado origen de la frase <b>&quot;Chocolate por la noticia&quot;</b>. Cómo circulaba la información. El teatro
          y la noticia de la invasión inglesa en 1806.<br/>
          Difusión de las nuevas ideas: Belgrano, Castelli y Vieytes discuten sobre la libertad, las nuevas
          ciencias y el libre pensamiento.<br/>
          <b>Lugares secretos para reuniones revolucionarias:</b> Café de Marco, las tertulias, Jabonería de Vieytes.
          Participación de las mujeres: la tertulia comandada por Casilda Rodríguez Peña.<br/>
          Noticias falsas, verdades ocultas: el Virrey inventando un diario con &quot;fake news&quot;. Belgrano y Vieytes
          creando el Correo de Comercio.<br/>
          <b>Las contraseñas secretas:</b> Belgrano, la semana de mayo y un misterioso pañuelo. Los debates en el
          Cabildo Abierto del 22 de mayo
          `,
    destacado: "",
    tipoDeObra: "Comedia musical",
    pie: "",
    images: [
      {
        imgName: "chocolate01",
        url: chocolate01,
      },
      {
        imgName: "chocolate02",
        url: chocolate02,
      },
      {
        imgName: "chocolate03",
        url: chocolate03,
      },
      {
        imgName: "chocolate04",
        url: chocolate04,
      },
    ],
    temas: [],
    premios: [],
    keywords: [
      "Chocolate por la Libertad",
      "Las noticias, la prensa, las reuniones secretas",
      "Invasión inglesa",
      "Belgrano",
      "Castelli",
      "Vieytes",
      "Café de Marco",
      "Tertulias",
      "Jabonería de Vieytes",
      "Casilda Rodríguez Peña",
      "Fake news",
      "Correo de Comercio",
      "Contraseñas secretas",
      "Cabildo Abierto",
      "Comedia musical",
    ],
  }
  ,
  // mondongo
  {
    ID: "mondongo",
    temaObra: temasObras.revolucion,
    title: "Mondongo para Manuel",
    imgPortada: mondongoPortada,
    subTitle: "Las comidas y las formas de cocinarla en tiempos de Belgrano y la Revolución",
    startDate: createStartDate(),
    body: `
               Con la idea de contar las costumbres culinarias y de alimentación, la señorita Gutiérrez, su novio y el Director presentan esta comedia recurriendo a múltiples técnicas: comedia musical, títeres, bailes y, por supuesto, las precisas y sustanciosas explicaciones históricas que caracteriza a nuestras obras.<br/>
               Un mágico mondongo es preparado para Manuel Belgrano al ritmo de chacareras, zambas y candombes especialmente compuestos para la obra. Delante de los chicos se van armando tres escenas:
                `,
    destacado: "",
    tipoDeObra: "Comedia musical",
    pie: "",
    images: [
      {
        imgName: "mondongo001",
        url: mondongo001,
      },
      {
        imgName: "mondongo002",
        url: mondongo002,
      },
      {
        imgName: "mondongo003",
        url: mondongo003,
      },
      {
        imgName: "mondongo004",
        url: mondongo004,
      },
      {
        imgName: "mondongo005",
        url: mondongo005,
      },
      {
        imgName: "mondongo006",
        url: mondongo006,
      },
    ],
    temas: [],
    premios: [],
    keywords: [
      "Mondongo para Manuel",
      "Comidas",
      "Cocina en tiempos de Belgrano",
      "Costumbres culinarias",
      "Alimentación",
      "La Fonda de la Catalana",
      "Plaza del mercado",
      "Manuel Belgrano",
      "Cabildo",
      "Comedia musical",
    ],
  },
  // Para manuel belgrano y el 20 de junio
  // belgrano
  {
    ID: "belgrano",
    temaObra: temasObras.belgrano,
    title: "Manuel Belgrano, ensayo ¡General!",
    imgPortada: belgranoPortada,
    subTitle: "La vida de Belgrano",
    startDate: createStartDate(),
    body: `
                Una divertida comedia al servicio del general Manuel Belgrano para contar su niñez, su participación en la Revolución de Mayo, las guerras de la Independencia, sus romances…<br/>
                Para llevarla a cabo la señorita Gutiérrez y su novio Gutiérrez pergeñan un desopilante ensayo de títeres en el que el señor Director y el mismísimo general intervienen, uno con sus conocimientos y el otro con sus vivencias.<br/>
                Diferentes técnicas teatrales, teatro de sombras, títeres de todo tipo y un sorprendente final a toda orquesta.
                `,
    destacado: "",
    tipoDeObra: "Comedia histórica de actores y títeres",
    pie: "",
    images: [
      {
        imgName: "belgrano001",
        url: belgrano001,
      },
      {
        imgName: "belgrano002",
        url: belgrano002,
      },
      {
        imgName: "belgrano003",
        url: belgrano003,
      },
      {
        imgName: "belgrano004",
        url: belgrano004,
      },
      {
        imgName: "belgrano005",
        url: belgrano005,
      },
      {
        imgName: "belgrano006",
        url: belgrano006,
      },
      {
        imgName: "belgrano007",
        url: belgrano007,
      },
    ],
    temas: [],
    premios: [],
    keywords: [
      "Manuel Belgrano",
      "Revolución de Mayo",
      "Guerras de la Independencia",
      "Comedia histórica",
      "Títeres",
      "Teatro de sombras",
      "Romances",
      "Comedia histórica de actores y títeres",
    ],
  },
  // celestiblanca
  {
    ID: "celestiblanca",
    temaObra: temasObras.belgrano,
    title: "Celestiblanca",
    imgPortada: celestiblancaPortada,
    subTitle: "La misteriosa historia de la bandera",
    startDate: createStartDate(),
    body: `
          Gutiérrez hace gala de su reciente condición de “detective teatral” y le propone al director del museo
          aprovechar la tradicional “ciencia de la deducción” para esclarecer el misterio que rodea a la creación de
          la bandera argentina.<br/>
          De este modo irán presentando los momentos que llevaron a la creación de la bandera:
          Pueyrredón y “la medida” durante las invasiones inglesas. El escudo del Consulado fundado por
          Belgrano. El rey Carlos III y la famosa orden celeste y blanca. Las cartas entre Belgrano y el Triunvirato
          y sus vaivenes políticos. La historia de dos colores únicos entre todas las banderas. Los tres formatos
          originales de la bandera.<br/>
          Ideada a través de diferentes recursos y técnicas teatrales, la obra pretende reconstruir en la mente de los
          chicos el camino que tuvo que recorrer nuestra bandera hasta llegar a ser el emblema de un único
          sentimiento para todos los argentinos
          `,
    destacado: "",
    tipoDeObra: "Comedia histórica",
    pie: "Esta obra es para Segundo y Tercer ciclo. Cuando viaja a los colegios va acompañada de “Pequeño Corazón” para los más chicos",
    images: [],
    temas: [],
    premios: [],
    keywords: [
      "Creación de la bandera argentina",
      "Invasiones inglesas",
      "Escudo del Consulado",
      "Orden celeste y blanca",
      "Belgrano",
      "Triunvirato",
      "Recursos y técnicas teatrales",
      "Comedia histórica",
    ],
  },
  // corazón
  {
    ID: "corazon",
    temaObra: temasObras.belgrano,
    title: "Pequeño corazón",
    imgPortada: corazonPortada,
    subTitle: "La creación de la bandera",
    startDate: createStartDate(),
    body: `
                El viaje de Belgrano a Rosario, quince días de caminatas, problemas para encontrar agua para los soldados, dónde
                se hospedaban. Llegada a Rosario, la casa de sus amigos Catalina y Vicente Echevarría donde se hospedó
                Belgrano. Un divertido momento cocinando con Belgrano. ¡Qué es una batería? La construcción de las baterías
                Libertad e Independencia, el río Paraná, la isla, los cañones.<br/>
                La primera bandera, su confección, la elección de los colores, la instalación del primer mástil para la bandera, el
                primer abanderado. Todo con hermosas canciones y divertidas escenas.
                `,
    destacado: "",
    tipoDeObra: "Comedia musical",
    pie: "En las funciones viajeras en las escuelas, esta obra va acompañada de la comedia Celestiblanca para los más grandes.",
    images: [
      {
        imgName: "corazon001",
        url: corazon001,
      },
      {
        imgName: "corazon001",
        url: corazon002,
      },
    ],
    temas: [],
    premios: [],
    keywords: [
      "Belgrano",
      "Rosario",
      "Baterías Libertad e Independencia",
      "Confección de la primera bandera",
      "Colores de la bandera",
      "Mástil para la bandera",
      "Abanderado",
      "Comedia musical",
      "Viaje de Belgrano a Rosario",
      "Caminatas",
      "Problemas de suministro de agua",
      "Casa de los Echevarría",
      "Construcción de baterías",
      "Río Paraná",
      "Confección de la primera bandera",
      "Elección de colores",
      "Primer mástil para la bandera",
      "Primer abanderado",
      "Hermosas canciones",
      "Divertidas escenas",
    ],
  },
  // Dos tipos audaces
  {
    ID: "dosTipos",
    temaObra: temasObras.belgrano,
    title: "Dos tipos audaces",
    imgPortada: dosTiposPortada,
    subTitle: "Güemes y Belgrano",
    startDate: createStartDate(),
    body: `
  `,
    destacado: "ESTRENO 2024",
    tipoDeObra: "Comedia musical",
    pie: "",
    images: [],
    temas: [
      { tema: "Güemes y Belgrano presentados como nunca se los vio." },
      {
        tema: "Las vidas de estos dos audaces generales contadas en paralelo.",
      },
      { tema: "La casa natal de Manuel Belgrano, su numerosa familia." },
      { tema: "El papel de Güemes en las invasiones inglesas." },
      { tema: "Belgrano y la creación de un ejército y de una bandera." },
      {
        tema: "Güemes y sus gauchos frenando a los españoles. Belgrano y el éxodo Jujeño",
      },
    ],
    premios: [],
    keywords: [
      "Dos tipos audaces",
      "Güemes y Belgrano",
      "Comedia musical",
      "Estreno 2024",
      "Manuel Belgrano",
      "General Güemes",
      "Vidas paralelas",
      "Casa natal de Belgrano",
      "Invasiones inglesas",
      "Creación de la bandera",
      "Éxodo Jujeño",
      "Gauchos de Güemes",
      "Frenando a los españoles",
      "Historia argentina",
      "Héroes argentinos"
    ],
  }
  ,
  // El regalo de Belgrano
  {
    ID: "regaloBelgrano",
    temaObra: temasObras.belgrano,
    title: "El regalo de Belgrano",
    imgPortada: regaloBelgrano,
    subTitle: "Desde los juegos de infancia a la creación de la bandera",
    startDate: createStartDate(),
    body: `

                `,
    destacado: "ESTRENO 2024",
    tipoDeObra: "Comedia musical",
    pie: "Esta comedia es para inicial y primer ciclo. Cuando visita los colegios va acompañada de Dos tipos audaces (Belgrano y Güemes) para los más grandes.",
    images: [],
    temas: [
      { tema: `Cómo era la vida de Belgrano cuando era chico través de una hermosa casa de muñecas que representa la enorme casa familiar, con sus padres y hermanos.` },
      { tema: `Los juegos y juguetes de infancia con sus numerosos hermanos.` },
      { tema: `Su vida como general de la patria: enseñar y entrenar a los soldados a través de un insólito ritmo de blues.` },
      { tema: `El regalo que nos hizo Belgrano en una canción para recordarlo siempre.` },
    ],
    premios: [],
    keywords: [
      "Belgrano",
      "Infancia",
      "Juegos",
      "Juguetes",
      "General de la patria",
      "Entrenamiento de soldados",
      "Canción",
      "Creación de la bandera",
      "Comedia musical",
      "Vida de Belgrano en la infancia",
      "Juegos y juguetes de infancia",
      "General de la patria",
      "Enseñanza y entrenamiento de soldados",
      "Regalo de Belgrano",
    ],
  },
  // Para la independencia y el 9 de julio
  // 9cajones
  {
    ID: "9cajones",
    temaObra: temasObras.independencia,
    title: "Nueve cajones mágicos en julio",
    imgPortada: cajonesPortada,
    subTitle: "La historia de la Independencia",
    startDate: createStartDate(),
    body: `
                Una comedia que cuenta el difícil camino que llevó a nuestra Independencia. Los primeros movimientos
                revolucionarios, San Martín y la Asamblea del año XIII, el curioso nacimiento del Himno Nacional, el
                regreso de Fernando VII al trono y la inminencia de un ataque militar, Güemes y sus gauchos resistiendo
                en Salta, los congresales rumbo a Tucumán y un alegre festejo final.
                `,
    destacado: "",
    tipoDeObra: "Comedia histórica",
    pie: "Esta obra es para Segundo y Tercer ciclo. Cuando viaja a los colegios va acompañada de “Viajecitos de Independencia” para los más chicos",
    images: [
      {
        imgName: "9 cajones001",
        url: cajones001,
      },
      {
        imgName: "9 cajones002",
        url: cajones002,
      },
      {
        imgName: "9 cajones003",
        url: cajones003,
      },
      {
        imgName: "9 cajones004",
        url: cajones004,
      },
    ],
    temas: [],
    premios: [{ premio: "Premio Proteatro 2008" }],
    keywords: [
      "Independencia",
      "San Martín",
      "Asamblea del año XIII",
      "Himno Nacional",
      "Fernando VII",
      "Güemes",
      "Gauchos",
      "Salta",
      "Congresales",
      "Tucumán",
      "Comedia histórica",
    ],
  },
  // viajecitos
  {
    ID: "viajecitos",
    temaObra: temasObras.independencia,
    title: "Viajecitos de Independencia",
    imgPortada: viajecitosPortada,
    subTitle: "Una historia sobre la independencia",
    startDate: createStartDate(),
    body: `
                El director y sus tres ayudantes arman frente a los chicos una divertida comedia de títeres sobre viajes,
                encuentros y desencuentros de Miguel, ayudante de Belgrano y de Patricia, hija de un diputado del
                Congreso de Tucumán. Ambos se encuentran por casualidad y el romance se sella con una cita en la
                Recova. Pero el encuentro se frustra porque Belgrano y Miguel son enviados a España por el gobierno en
                misión diplomática. Viaje en barco rumbo a España y un divertido encuentro con el rey de España. A su
                regreso Miguel se entera que Patricia acompañó a su padre a Tucumán, la pena dura poco ya que, para su
                alegría, Belgrano parte rumbo a Tucumán en un interminable viaje en carreta.<br/>
                Los viajes, las costumbres, los medios de trasporte de la época, los trámites para declarar la
                Independencia y los festejos como marco de esta romántica aventura.
                `,
    destacado: "",
    tipoDeObra: "Comedia histórica para títeres y actores",
    pie: "Esta obra es para Inicial y Primer ciclo. Cuando viaja a los colegios va acompañada de “Nueve cajones mágicos” para los más grandes",
    images: [
      {
        imgName: "viajecitos002",
        url: viajecitos002,
      },
      {
        imgName: "viajecitos003",
        url: viajecitos003,
      },
      {
        imgName: "viajecitos004",
        url: viajecitos004,
      },
      {
        imgName: "viajecitos005",
        url: viajecitos005,
      },
      {
        imgName: "viajecitos006",
        url: viajecitos006,
      },
      {
        imgName: "viajecitos007",
        url: viajecitos007,
      },
    ],
    temas: [],
    premios: [],
    keywords: [
      "Independencia",
      "Belgrano",
      "Tucumán",
      "España",
      "Viajes",
      "Encuentros",
      "Desencuentros",
      "Romance",
      "Títeres",
      "Comedia histórica",
    ],
  },
  // alegria
  {
    ID: "alegria",
    temaObra: temasObras.independencia,
    title: "Alegría de la Libertad",
    imgPortada: alegriaPortada,
    subTitle: "La Declaración de Independencia",
    startDate: createStartDate(),
    body: `
                La señorita Gutiérrez quiere impresionar al director construyendo una carroza alegórica para el desfile del
                9 de Julio. Secundada por un músico en vivo, logrará convencerlo para que la ayude a seleccionar los
                símbolos de la Independencia. El director, por su parte, aprovechará la ocasión para contar porqué se
                tardó seis años en declararla, detallar los problemas de la época como los espías europeos, las grandes
                distancias, los enemigos internos y el peligro de invasión realista.<br/>
                San Martín, Belgrano y Güemes respaldando a los diputados en Tucumán. Difusión del Acta de la
                Independencia: chasquis a todo el país, mensajeros secretos a Chile, viaje alrededor del mundo en la
                fragata La Argentina y cuál fue el primer país en reconocer nuestra libertad.
                `,
    destacado: "",
    tipoDeObra: "Comedia musical con música en vivo",
    pie: "Esta obra es para Segundo y Tercer ciclo. Cuando viaja a los colegios va acompañada de “Caminito Criollo” para los más chicos",
    images: [
      {
        imgName: "alegria001",
        url: alegria001,
      },
      {
        imgName: "alegria002",
        url: alegria002,
      },
      {
        imgName: "alegria003",
        url: alegria003,
      },
      {
        imgName: "alegria004",
        url: alegria004,
      },
      {
        imgName: "alegria005",
        url: alegria005,
      },
      {
        imgName: "alegria006",
        url: alegria006,
      },
      {
        imgName: "alegria007",
        url: alegria007,
      },
    ],
    temas: [],
    premios: [],
    keywords: [
      "Independencia",
      "Declaración de Independencia",
      "9 de Julio",
      "Carroza alegórica",
      "Música en vivo",
      "San Martín",
      "Belgrano",
      "Güemes",
      "Acta de la Independencia",
      "Chasquis",
      "Fragata La Argentina",
    ],
  },
  // caminito
  {
    ID: "caminito",
    temaObra: temasObras.independencia,
    title: "Caminito Criollo",
    imgPortada: caminitoPortada,
    subTitle: "Los transportes en tiempos de la Independencia",
    startDate: createStartDate(),
    body: `
                La señorita Gutiérrez y el señor Director presentan las tres formas de hacer un viaje en 1816. A caballo,
                en carreta y en galera.<br/>
                Con música y bailes se mostrará cómo era unan una galera y una carreta por dentro, con qué materiales
                estaban hechas, cómo eran los asientos, qué cosas llevaban, los alimentos, la vestimenta de los carreteros.
                Las peripecias de un viaje a caballo, sin mapas ni caminos, cruzando ríos caudalosos sin puentes. Cómo
                era una posta por dentro.
                `,
    destacado: "",
    tipoDeObra: "Comedia musical",
    pie: "Esta obra es para Inicial y Primer ciclo. Cuando viaja a los colegios va acompañada de “Alegría de la Libertad” para los más grandes",
    images: [
      {
        imgName: "caminito001",
        url: caminito001,
      },
      {
        imgName: "caminito002",
        url: caminito002,
      },
    ],
    temas: [],
    premios: [],
    keywords: [
      "transportes", "Independencia", "viaje", "carreta", "galera", "caballo", "postas",
    ],
  },
  // casita
  {
    ID: "casita",
    temaObra: temasObras.independencia,
    title: "Habla la casita",
    imgPortada: casitaPortada,
    subTitle: "Historia de la Casa de Tucumán",
    startDate: createStartDate(),
    body: `
        Nace la casa como regalo de bodas, con serenata de amor a la joven Francisca y un desopilante pedido de mano. Los años que pasan, la casa como cuartel para el general Belgrano, la cocina de doña Francisca y una musical preparación de mazamorra para los soldados. Los arreglos para el Congreso, la declaración de Independencia y la casa como testigo de de doscientos años de historia. Una divertida comedia con música en vivo en la que los Gutiérrez y el director son sorprendidos por los relatos de una insólita casita.
                `,
    destacado: "",
    tipoDeObra: "Comedia musical con música en vivo",
    pie: "Cuando viaja a la escuela, esta obra tiene una adaptación especial para inicial y primer ciclo",
    images: [
      {
        imgName: "casita001",
        url: casita001,
      },
      {
        imgName: "casita002",
        url: casita002,
      },
      {
        imgName: "casita003",
        url: casita003,
      },
      {
        imgName: "casita004",
        url: casita004,
      },
    ],
    temas: [],
    premios: [],
    keywords: [
      "transportes en tiempos de la Independencia", "viaje en 1816", "carreta", "galera", "caballo", "postas",
    ],
  },
  // Para San Martín y el 17 de agosto
  // sanMartin
  {
    ID: "sanMartin",
    temaObra: temasObras.sanMartin,
    title: "San Martín, un general sin Remedios",
    imgPortada: sanMartinPortada,
    subTitle: "La vida de San Martín",
    startDate: createStartDate(),
    body: ``,
    destacado: "ESTRENO NOVIEMBRE 2023",
    tipoDeObra: "Comedia hitórica",
    pie: "Esta obra es para Segundo y Tercer ciclo. Cuando viaja a los colegios va acompañada de “Empanadas para José” o “Cuentos con granaderos” para los más chicos",
    images: [
      {
        imgName: "sanMartin001",
        url: sanMartin001,
      },
      {
        imgName: "sanMartin002",
        url: sanMartin002,
      },
      {
        imgName: "sanMartin003",
        url: sanMartin003,
      },
      {
        imgName: "sanMartin004",
        url: sanMartin004,
      },
      {
        imgName: "sanMartin005",
        url: sanMartin005,
      },
      {
        imgName: "sanMartin006",
        url: sanMartin006,
      },
      {
        imgName: "sanMartin007",
        url: sanMartin007,
      },
    ],
    temas: [
      { tema: `San Martín visto desde la óptica de su esposa, Remeditos.` },
      {
        tema: `Cómo y dónde se conocieron, se casaron, fueron de luna de miel…`,
      },
      {
        tema: `La participación de Remedios en los preparativos para el Cruce de los Andes`,
      },
      {
        tema: `La creación de los Granaderos, el combate de San Lorenzo, Fray Luis Beltrán y el difícil cruce de la
                    cordillera. La música de entonces en escena, actores vestidos de época y una ingeniosa escenografía
                    completan esta singular biografía teatral.`,
      },
    ],
    premios: [{ premio: "Premio Proteatro 2003" }],
    keywords: [
      "San Martín",
      "Remedios de Escalada",
      "Cruce de los Andes",
      "Granaderos",
      "Combate de San Lorenzo",
      "Fray Luis Beltrán",
      "Cordillera",
      "Escenografía",
      "Biografía teatral",
    ],

  },
  // empanadas
  {
    ID: "empanadas",
    temaObra: temasObras.sanMartin,
    title: "Empanadas para José",
    imgPortada: empanadasPortada,
    subTitle: "La vida de San Martín",
    startDate: createStartDate(),
    body: ``,
    destacado: "ESTRENO NOVIEMBRE 2023",
    tipoDeObra: "Comedia con música en vivo",
    pie: "Esta obra es para Inicial y Primer ciclo. Cuando viaja a los colegios va acompañada de “Un general sin Remedios” para los más grandes",
    images: [
      {
        imgName: "empanadas001",
        url: empanadas001,
      },
      {
        imgName: "empanadas002",
        url: empanadas002,
      },
      {
        imgName: "empanadas003",
        url: empanadas003,
      },
      {
        imgName: "empanadas004",
        url: empanadas004,
      },
      {
        imgName: "empanadas005",
        url: empanadas005,
      },
      {
        imgName: "empanadas006",
        url: empanadas006,
      },
      {
        imgName: "empanadas007",
        url: empanadas007,
      },
      {
        imgName: "empanadas008",
        url: empanadas008,
      },
      {
        imgName: "empanadas009",
        url: empanadas009,
      },
      {
        imgName: "empanadas010",
        url: empanadas010,
      },
      {
        imgName: "empanadas011",
        url: empanadas011,
      },
      {
        imgName: "empanadas012",
        url: empanadas012,
      },
      {
        imgName: "empanadas013",
        url: empanadas013,
      },
    ],
    temas: [
      {
        tema: `Ante la sorpresa del director, Gutiérrez presenta “La ruta de la empanada sanmartiniana”, emprendimiento turístico-gastronómico-teatral relacionado con la vida de San Martín.`,
      },
      {
        tema: `Infancia en Yapeyú: canciones costeras y empanadas correntinas.`,
      },
      {
        tema: `San Martín como un típico capitán español: baile, canto y empanadas andaluzas en un simpático tablado.`,
      },
      {
        tema: `Regreso a Buenos Aires: un musical encuentro con Remedios y las empanadas porteñas ofrecidas por los vendedores de la Recova.`,
      },
      {
        tema: `Al Norte y al Oeste, cruzando Los Andes en busca de otros sabores y nuestra Libertad al ritmo del candombe.`,
      },
    ],
    premios: [],
  },
  // granaderos
  {
    ID: "granaderos",
    temaObra: temasObras.sanMartin,
    title: "Cuentos con granaderos",
    imgPortada: granaderosPortada,
    subTitle: "Dos historias sobre San Martín y los granaderos",
    startDate: createStartDate(),
    body: ``,
    destacado: "",
    tipoDeObra: "Comedia histórica",
    pie: "Esta obra es para Inicial y Primer ciclo. Cuando viaja a los colegios va acompañada de “Un general sin Remedios” para los más grandes",
    images: [
      {
        imgName: "empanadas001",
        url: empanadas001,
      },
      {
        imgName: "empanadas002",
        url: empanadas002,
      },
      {
        imgName: "empanadas003",
        url: empanadas003,
      },
      {
        imgName: "empanadas004",
        url: empanadas004,
      },
      {
        imgName: "empanadas005",
        url: empanadas005,
      },
      {
        imgName: "empanadas006",
        url: empanadas006,
      },
      {
        imgName: "empanadas007",
        url: empanadas007,
      },
      {
        imgName: "empanadas008",
        url: empanadas008,
      },
      {
        imgName: "empanadas009",
        url: empanadas009,
      },
      {
        imgName: "empanadas010",
        url: empanadas010,
      },
      {
        imgName: "empanadas011",
        url: empanadas011,
      },
      {
        imgName: "empanadas012",
        url: empanadas012,
      },
      {
        imgName: "empanadas013",
        url: empanadas013,
      },
    ],
    temas: [
      {
        tema: `Gutiérrez quiere escribir un cuento sobre San Martín y como no sabe, el Director y la señorita Gutiérrez lo van a ayudar.`,
      },
      {
        tema: `Así presentan una curiosa cena navideña contada por el abuelo San Martín a sus nietas, a Remedios buscando por las tiendas de Mendoza las telas para la bandera de los Andes y a las damas mendocinas cosiéndola.`,
      },
      {
        tema: `Por último se presentan los trabajos previos a la partida a Chile, la preparación de los pertrechos y un desopilante “ensayo” de cruce a cargo de Fray Luis Beltrán.`,
      },
    ],
    premios: [],
    keywords: [
      "San Martín",
      "Granaderos",
      "Historias",
      "Navidad",
      "Mendoza",
      "Preparativos militares",
      "Bandera de los Andes",
      "Partida a Chile",
      "Fray Luis Beltrán",
      "Ensayo cómico",
    ],

  },
  // jugueteria
  {
    ID: "jugueteria",
    temaObra: temasObras.sanMartin,
    title: "La juguetería de José",
    imgPortada: jugueteriaPortada,
    subTitle: "Historia de cómo se organizó el Cruce de los Andes",
    startDate: createStartDate(),
    body: `
                En el marco de una juguetería los Gutiérrez y el director cuentan los complicados preparativos que durante cuatro años llevó adelante San Martín junto con la gente de todo el país para armar el ejército que cruzaría Los Andes.<br/>
                Con música en vivo se presenta: un día en el campamento del Plumerillo, Remedios, la bandera y una serenata; espías y agentes secretos; comida, ropa, monturas y miles de cosas que constantemente llegan a Mendoza desde todo el país.<br/>
                `,
    destacado: "ESTRENO NOVIEMBRE 2023",
    tipoDeObra: "Comedia musical",
    pie: "Comedia musical para juguetes, actores y músicos.",
    images: [
      {
        imgName: "jugueteria001",
        url: jugueteria001,
      },
      {
        imgName: "jugueteria002",
        url: jugueteria002,
      },
      {
        imgName: "jugueteria003",
        url: jugueteria003,
      },
    ],
    temas: [],
    premios: [],
  },
  // libertadores
  {
    ID: "libertadores",
    temaObra: temasObras.sanMartin,
    title: "Libertadores de América",
    imgPortada: libertadoresPortada,
    subTitle:
      "San Martín, Bolívar, Güemes, Las Heras, Francisco Miranda, Fray Luis Beltrán",
    startDate: createStartDate(),
    body: `
                Creyendo que se trata de una obra sobre la Copa Libertadores, Gutiérrez prepara todo para hablar de
                fútbol, como de costumbre el director resuelve el error y aprovecha la escenografía y la música en vivo
                para hablar sobre San Martín.<br/>
                Los luchadores de la “Patria Grande”. El Cruce de Los Andes. La participación a préstamo de Bolívar y
                Sucre. Güemes y Belgrano jugando a la defensiva y San Martín de número 5 organizando el juego, Las
                Heras, Balcarce y O´Higgins: una delantera increíble.<br/>
                El papel de Simón Bolívar y Francisco Miranda en la lucha por la libertad latinoamerciana
                `,
    destacado: "",
    tipoDeObra: "Comedia con música en vivo",
    pie: "Esta obra es para Segundo y Tercer ciclo. Cuando viaja a los colegios va acompañada de “La juguetería de José” para los más chicos",
    images: [
      {
        imgName: "libertadores002",
        url: libertadores002,
      },
      {
        imgName: "libertadores003",
        url: libertadores003,
      },
      {
        imgName: "libertadores004",
        url: libertadores004,
      },
      {
        imgName: "libertadores005",
        url: libertadores005,
      },
      {
        imgName: "libertadores006",
        url: libertadores006,
      },
      {
        imgName: "libertadores007",
        url: libertadores007,
      },
    ],
    temas: [],
    premios: [],
    keywords: [
      "Cruce de los Andes", "San Martín", "logística militar", "juguetería histórica", "preparativos del ejército", "historia argentina para niños", "comedia musical infantil", "campamento del Plumerillo", "bandera de los Andes", "operaciones encubiertas", "estrategia militar",
    ],
  },
  // PARA SARMIENTO Y EL 11 DE SEPTIEMBRE
  // sarmiento
  {
    ID: "sarmiento",
    temaObra: temasObras.sarmiento,
    title: "Sarmiento, un Domingo en la escuela",
    imgPortada: sarmientoPortda,
    subTitle: "Vida de Domingo F. Sarmiento",
    startDate: createStartDate(),
    body: `Esta vez la señorita Gutiérrez y su novio quieren sorprender al señor Director con una nueva y fascinante experiencia circense pero serán ellos los sorprendidos: el señor Director le tiene preparado un final educativo al mejor estilo de Sarmiento.`,
    destacado: "",
    tipoDeObra: "Comedia histórica",
    pie: "Septiembre en el museo y viajera en las escuelas",
    images: [
      {
        imgName: "Sarmiento001",
        url: sarmiento001,
      },
      {
        imgName: "Sarmiento002",
        url: sarmiento002,
      },
      {
        imgName: "Sarmiento003",
        url: sarmiento003,
      },
      {
        imgName: "Sarmiento004",
        url: sarmiento004,
      },
    ],
    temas: [
      {
        tema: `La vida de Sarmiento, su mal carácter, amores, viajes, exilios, escritos. Su incansable lucha política.`,
      },
      {
        tema: `Las ideas de Sarmiento: inmigración, industrialización, progreso y modernización del país. Su obra como presidente.`,
      },
      {
        tema: `La educación argentina: los antiguos métodos de enseñanza. Reformas y planes educativos de Sarmiento.`,
      },
      { tema: `Las primeras maestras.` },
    ],
    premios: [
      { premio: "Premio Proteatro 2005" },
      { premio: "Premio Fondo Nacional de las Artes 2005" },
      { premio: "Premio Instituto Nacional del Teatro 2006" },
    ],
    keywords: [
      "Domingo F. Sarmiento", "comedia histórica", "vida de Sarmiento", "lucha política", "ideas de Sarmiento", "reformas educativas", "presidencia de Sarmiento", "educación argentina", "maestras argentinas", "progreso", "modernización", "industrialización", "inmigración", "viajes", "exilios", "escritos de Sarmiento", "mal carácter", "métodos de enseñanza", "campaña educativa", "circo educativo",
    ],
  },
  // PARA LA DIVERSIDAD CULTURAL Y EL 12 DE OCTUBRE
  // aLaMar
  {
    ID: "aLaMar",
    temaObra: temasObras.dievrsidad,
    title: "Juntos a la mar",
    imgPortada: aLaMarPortada,
    subTitle: "Los sueños de Colón, su vida en Portugal y sus viajes",
    startDate: createStartDate(),
    body: `
                Para obtener el puesto de ayudante del museo, los Gutiérrez deberán contar con música y títeres dos
                aspectos de la vida de Colón. Uno de ellos elige contar los interesantes años de Colón en Portugal cuando 
                estudia cartografía, se casa y concreta la ideal del famoso viaje. Y el otro cuenta un desopilante día a
                bordo de la carabela representada por una hermosa maqueta de 8 m de largo.
                `,
    destacado: "",
    tipoDeObra: "Comedia musical",
    pie: "",
    images: [
      {
        imgName: "aLaMar002",
        url: aLaMar002,
      },
      {
        imgName: "aLaMar003",
        url: aLaMar003,
      },
      {
        imgName: "aLaMar004",
        url: aLaMar004,
      },
      {
        imgName: "aLaMar001",
        url: aLaMar001,
      },
    ],
    temas: [],
    premios: [{ premio: "Premio Proteatro 2015" }],

  },
  // adentroTierra
  {
    ID: "adentroTierra",
    temaObra: temasObras.dievrsidad,
    title: "Viaje adentro de la Tierra",
    imgPortada: adentroTierraPortda,
    subTitle: "Los pueblos originarios y arqueología de Buenos Aires",
    startDate: createStartDate(),
    body: `
                Una divertida excavación arqueológica encabezada por los Gutiérrez y el Director.<be/>
                Un viaje hacia el pasado sin cambiar de lugar descubriendo que nuestra historia está todo el tiempo bajo
                nuestros pies, escondida en el suelo del colegio, de nuestras casas, del teatro.<br/>
                Un viaje hacia adentro de la tierra y hacia atrás en el tiempo en el cual encontraremos desde una moneda
                con la fecha de fundación del colegio hasta una flecha del pueblo originario que habitó Buenos Aires: los
                Het (o pampas o querandíes), su historia, sus costumbres.<br/>
                Un inesperado y sorprendente final obliga a los Gutiérrez a convertirse en paleontólogos.
                `,
    destacado: "",
    tipoDeObra: "Comedia musical",
    pie: "",
    images: [
      {
        imgName: "AdentroTierra001",
        url: adentroTierra001,
      },
      {
        imgName: "AdentroTierra002",
        url: adentroTierra002,
      },
      {
        imgName: "AdentroTierra003",
        url: adentroTierra003,
      },
    ],
    temas: [],
    premios: [],
    keywords: [
      "Colón", "vida de Colón", "sueños de Colón", "viajes de Colón", "Portugal", "cartografía", "viaje a América", "carabela", "día a bordo", "comedia musical", "títeres", "maqueta", "historia de Colón", "exploración", "descubrimiento de América", "experiencia en Portugal",
    ],
  },
  // originaria
  {
    ID: "originaria",
    temaObra: temasObras.dievrsidad,
    title: "Originariamente Argentina",
    imgPortada: originariaPortda,
    subTitle: "Diaguitas, yámanas tobas, guaraníes, querandíes",
    startDate: createStartDate(),
    body: `
                Comedia musical sobre La Tierra Argentina desde la llegada de los primeros pobladores de nuestro
                territorio, hace unos 15.000 años.<br/>
                El arte y la vida cotidiana de los pueblos aborígenes y sus ancestros. Guaraníes, Tobas, Diaguitas, Onas,
                Querandíes y Mapuches son algunos de los pueblos presentados.<br/>
                Esta vez Gutiérrez aprendió el arte de la luthería pero en vez de construir instrumentos musicales
                corrientes nos presenta los más extravagantes aparatos que no solo hacen música y la interpretan en
                escena sino que, de manera insólita, proveen los materiales escenográficos que el Director le solicita.
                `,
    destacado: "",
    tipoDeObra: "Comedia musical con música en vivo",
    pie: "",
    images: [
      {
        imgName: "OriginariaImg01",
        url: originaria001,
      },
      {
        imgName: "OriginariaImg02",
        url: originaria002,
      },
      {
        imgName: "OriginariaImg03",
        url: originaria003,
      },
    ],
    temas: [],

    premios: [],
    keywords: [
      "pueblos originarios", "Argentina", "pobladores", "aborígenes", "Diaguitas", "Yámanas", "Tobas", "Guaraníes", "Querandíes", "historia", "cultura", "vida cotidiana", "ancestros", "comedia musical", "música en vivo", "luthería", "instrumentos musicales", "escenografía", "arte", "diversidad cultural",
    ],
  },
  // PARA EL DIA DE LA TRADICIÓN Y EL 11 DE NOVIEMBRE
  // tesoro
  {
    ID: "tesoro",
    temaObra: temasObras.tradicion,
    title: "El tesoro Argentino",
    imgPortada: tesoroPortda,
    subTitle: "Las tradiciones argentinas",
    startDate: createStartDate(),
    body: ` 
                El director del museo propone una obra sobre las tradiciones argentinas, intrigados por el título, los
                Gutiérrez deberán obtener, en las divertidas escenas propuestas por el director, las pistas que los lleven a
                develar cuál ese tesoro tradicional que conecta el pasado y el presente de los argentinos.<br/>
                De este modo se hablará del curioso origen de las bombachas del gaucho actual, del chiripá, el mate y su
                pasado guaraní, de Martín Fierro y el nuevo lector, entre otros temas.
                `,
    destacado: "",
    tipoDeObra: "Comedia histórica sobre las tradiciones",
    pie: "",
    images: [
      {
        imgName: "tesoro001",
        url: tesoro001,
      },
      {
        imgName: "tesoro002",
        url: tesoro002,
      },
      {
        imgName: "tesoro003",
        url: tesoro003,
      },
      {
        imgName: "tesoro004",
        url: tesoro004,
      },
      {
        imgName: "tesoro005",
        url: tesoro005,
      },
      {
        imgName: "tesoro006",
        url: tesoro006,
      },
      {
        imgName: "tesoro007",
        url: tesoro007,
      },
      {
        imgName: "tesoro008",
        url: tesoro008,
      },
      {
        imgName: "tesoro009",
        url: tesoro009,
      },
      {
        imgName: "tesoro010",
        url: tesoro010,
      },
      {
        imgName: "tesoro011",
        url: tesoro011,
      },
    ],
    temas: [],
    premios: [],
    keywords: [
      "tradiciones argentinas", "cultura", "historia", "gauchos", "bombachas", "chiripá", "mate", "Martín Fierro", "comedia histórica", "tesoro", "pasado y presente", "origen", "curiosidades", "gastronomía", "costumbres", "identidad nacional",
    ],
  },
  // PARA EL DIA DE LA SOBERANÍA (COMBATE DE VUELTA DE OBLIGADO)
  // obligada
  {
    ID: "obligada",
    temaObra: temasObras.soberania,
    title: "Obligada estaba la vuelta",
    imgPortada: obligadaPortda,
    subTitle: "Rosas, la Vuelta de Obligado y la defensa de la soberanía",
    startDate: createStartDate({ year: 2024, month: 3 }),
    body: ``,
    destacado: "",
    tipoDeObra: "Comedia musical",
    pie: "",
    images: [
      {
        imgName: "obligada001",
        url: obligada001,
      },
      {
        imgName: "obligada002",
        url: obligada002,
      },
      {
        imgName: "obligada003",
        url: obligada003,
      },
    ],
    temas: [
      {
        tema: `Una comedia musical sobre Rosas, la defensa de la soberanía y la Guerra del Paraná.`,
      },
      {
        tema: `Rosas, Manuelita y Mansilla enfrentan una amenaza abusiva y un combate desigual. Un brillante plan de defensa logra la victoria final.`,
      },
      {
        tema: `Vida cotidiana de Rosas en su casa de Palermo. Manuelita y su hermoso vestido de baile.`,
      },
      {
        tema: `¿En qué consistió la Guerra del Paraná? ¿Ganamos o perdimos esa guerra? ¿Contra quién peleamos?`,
      },
      {
        tema: `¿Por qué la derrota en Vuelta de Obligado simboliza la soberanía? ¿Qué papel jugó la primera deuda externa que tuvimos? ¿Alguno lo sabe?`,
      },
    ],
    premios: [],
    keywords: [
      "Rosas", "Vuelta de Obligado", "soberanía", "Guerra del Paraná", "defensa", "comedia musical", "Manuelita", "Mansilla", "combate desigual", "victoria", "Palermo", "vida cotidiana", "vestido de baile", "historia argentina", "Guerra del Paraná", "deuda externa", "identidad nacional",
    ],
  },
  // PARA FESTEJAR FIN DE AÑO
  // regalo
  {
    ID: "regalo",
    temaObra: temasObras.finDeAnio,
    title: "El mágico viaje de Papá Noel",
    imgPortada: regaloPortada,
    subTitle: "",
    startDate: createStartDate(),
    body: `
                Cómo se le ocurrió a Papá Noel la idea de hacer regalos sorpresa para todos los chicos el mundo.
Un mágico viaje musical por los países que visita: Grecia, Holanda, Italia, Rusia, Estados Unidos.
Finalmente llega a Buenos Aires para dejar un sorprendente regalo gigante, sacarse fotos con los chicos y recibir las cartitas.
                `,
    destacado: "",
    tipoDeObra: "Comedia musical",
    pie: "",
    images: [
      {
        imgName: "regalo002",
        url: regalo002,
      },
      {
        imgName: "obligada003",
        url: regalo003,
      },
      {
        imgName: "obligada004",
        url: regalo004,
      },
    ],
    temas: [],
    premios: [],
    keywords: [
      "Papá Noel", "Reyes Magos", "navidad", "comedia musical", "campaña publicitaria", "fanática", "magia", "bailes", "canciones", "anécdotas", "viajes", "dramatizaciones", "regalo", "especial",
    ],
  },
  // PARA ADULTOS Y SECUNDARIOS
  // feria
  {
    ID: "feria",
    temaObra: temasObras.adultos,
    title: "Tres en una feria",
    imgPortada: feriaPortada,
    subTitle: "Sobreviviendo al naufragio del 2001",
    startDate: createStartDate(),
    body: `
                Tres mujeres durante el verano del 2002 sobrellevando con humor los embates de la crisis del corralito.
                Una feria del trueque solidario, un “nodo” que también es un nudo difícil de desatar.
                Versión libre de tres cuentos de Roberto Fontanarrosa. Una mirada muy especial sobre el mundo
                femenino y un homenaje a aquellos hombres y mujeres anónimos que también supieron pilotear el país
                en medio de un naufragio. Al mejor estilo del teatro de Shakespeare: la escena es llevada adelante
                solamente por actores varones.
                `,
    destacado: "",
    tipoDeObra: "Comedia para secundarios y adultos",
    pie: "",
    images: [
      {
        imgName: "feria002",
        url: feria002,
      },
      {
        imgName: "feria003",
        url: feria003,
      },
      {
        imgName: "feria004",
        url: feria004,
      },
    ],
    temas: [],
    premios: [],
    keywords: [
      "verano", "crisis económica", "corralito", "feria del trueque", "solidaridad", "naufragio", "2001", "humor", "Roberto Fontanarrosa", "teatro", "Shakespeare", "mujeres", "hombres", "anónimos", "secundarios", "adultos",
    ],
  },

];

const stats = [
  {
    counterId: "anios",
    title: "Años de trayectoria",
    start: 0,
    end: calcularEdadEnAnios(1995),
    enableScrollSpy: true,
    duration: 2, // en segundos
    scrollSpyDelay: 300, // en miliseg
    scrollSpyOnce: false,
  },
  {
    counterId: "obrasEscritas",
    title: "Obras escrtiras",
    start: 0,
    end: 35,
    enableScrollSpy: true,
    duration: 2, // en segundos
    scrollSpyDelay: 300, // en miliseg
    scrollSpyOnce: false,
  },
  {
    counterId: "obrasMuseo",
    title: "Obras en cartel",
    start: 0,
    end: dataSource.length,
    enableScrollSpy: true,
    duration: 2, // en segundos
    scrollSpyDelay: 300, // en miliseg
    scrollSpyOnce: false,
  },
  {
    counterId: "publicaciones",
    title: "Libros publicados",
    start: 0,
    end: 3,
    enableScrollSpy: true,
    duration: 2, // en segundos
    scrollSpyDelay: 300, // en miliseg
    scrollSpyOnce: false,
  },
  {
    counterId: "funsiones",
    title: "Funciones realizadas",
    start: 0,
    end: 21253,
    enableScrollSpy: true,
    duration: 2, // en segundos
    scrollSpyDelay: 300, // en miliseg
    scrollSpyOnce: false,
  },
  // {
  //     counterId: 'publico',
  //     title: 'Espectadores',
  //     start: 0,
  //     end: 5480000,
  //     enableScrollSpy: true,
  //     duration: 2, // en segundos
  //     scrollSpyDelay: 1000, // en miliseg
  //     scrollSpyOnce: false,
  // },
];

const footerData = {
  contactChanels: [
    {
      chanel: "email",
      buttonIcon: faEnvelope,
      label: contactData.eMail,
      link: `mailto:${contactData.eMail.replace(/\s+/g, "")}`,
    },
    {
      chanel: "phone",
      buttonIcon: faMobileScreenButton,
      label: contactData.phone,
      link: `tel:${contactData.phone.replace(/\s+/g, "")}`,
    },
    {
      chanel: "celPhone",
      buttonIcon: faWhatsapp,
      label: contactData.celPhone,
      link: `https://wa.me/${process.env.REACT_APP_WHATEL}/?text=Hola%20Museo%20Viajero`,
    },
  ],
};


export {
  paths,
  dataSource,
  navBarNavigation,
  temasObras,
  temasObrasArray,
  ciclos,
  socialMedia,
  sponsors,
  nosotrosData,
  footerData,
  stats,
  cronologia,
  publicaciones,
};
